/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*------------------
     Animations
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*-------------------
      Opacities
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
:root {
  --panel-colour: 1px solid #dee0e2;
  --secondary-text-colour: #6f777b;
  --grey-4: #f8f8f8;
  --link-colour: #005ea5;
  --page-colour: #fff;
}
.library-listing__sidebar {
  list-style: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}
.library-listing__sidebar__link.active button {
  background-color: #1d70b8;
  border-color: #1d70b8;
}
.library-listing__sidebar__link button {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 15px 30px 20px 15px;
}
.library-listing__sidebar__link button:after {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0.5em;
  float: right;
  content: '\203A';
}
.site-search-wrapper {
  position: relative;
  width: 100%;
  max-width: calc(100% - 75px);
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  display: flex;
}
@media (min-width: 40.0625em) {
  .site-search-wrapper {
    font-size: 0.95em;
    width: 300px;
    max-width: 100%;
    float: none;
  }
}
@media (min-width: 48.0625em) {
  .site-search-wrapper {
    margin: 0;
    margin-top: -5px;
    float: right;
  }
}
.sidenavWidget {
  margin-bottom: 30px;
}
.sidenavWidget .level-1 {
  -webkit-padding-start: 15px !important;
          padding-inline-start: 15px !important;
}
.sidenavWidget .level-2 {
  -webkit-padding-start: 30px !important;
          padding-inline-start: 30px !important;
}
.sidenavWidget .level-3 {
  -webkit-padding-start: 45px !important;
          padding-inline-start: 45px !important;
}
.sidenavWidget .level-4 {
  -webkit-padding-start: 60px !important;
          padding-inline-start: 60px !important;
}
.customSearch {
  width: 60%;
}
.customSearch > div.ui > input.prompt {
  border: none;
  border-bottom: 1px solid #C7D5D8;
  border-radius: 0;
}
.customSearch > div.ui > i {
  display: none;
}
#admin .ui.menu.secondary .menu {
  margin-inline: auto;
}
#admin .ui.tabular.menu .item svg {
  -webkit-margin-end: 0.25em;
          margin-inline-end: 0.25em;
}
#admin .ui.tabular.menu + .attached.segment {
  border: 1px solid #C7D5D8;
  border-top: none;
  min-height: 40em;
}
#admin .avatar {
  margin-block: 0.25em;
}
#admin .avatar.default {
  background-color: teal;
}
#admin .avatar.currentUser {
  outline: 3px solid #007eb1;
  outline-offset: 1px;
  -webkit-margin-end: 0.5em;
          margin-inline-end: 0.5em;
}
#admin .avatar:not(.ui) {
  display: inline-block;
  vertical-align: top;
}
#admin .avatar:not(.ui) svg text {
  transform: translate(0, 1px);
}
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
          clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.header .dropdown i {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  body.has-toolbar-collapsed #main > div.content-area {
    min-height: calc(100vh - 345px);
  }
  body.has-toolbar #main > div.content-area {
    min-height: calc(100vh - 425px);
  }
  body:not(.has-toolbar) #main > div.content-area,
  body:not(.has-toolbar-collapsed) #main > div.content-area {
    min-height: calc(100vh - 287px);
  }
  .ui.basic.segment .header div.tools-search-wrapper div.search {
    display: none;
  }
}
@media only screen and (min-width: 767px) {
  #main > div.content-area {
    min-height: calc(100vh - 287px);
  }
}
div.header-wrapper div.hamburger-wrapper {
  top: 20px;
}
div.header-wrapper .ui.dropdown .menu.right {
  left: unset;
  right: 0px;
}
.whitespace-'normal' {
  white-space: 'normal';
}
.whitespace-'nowrap' {
  white-space: 'nowrap';
}
.whitespace-'pre' {
  white-space: 'pre';
}
.whitespace-'pre-line' {
  white-space: 'pre-line';
}
.whitespace-'pre-wrap' {
  white-space: 'pre-wrap';
}
